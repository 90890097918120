import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCustomerDetailSelector } from 'redux/selectors';
import { useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

import { responsiveBreakpoints } from 'utils/utils';
import { isEmpty } from 'lodash';
import { PublicScreen } from 'routes/screen-names';

const Header2Public = () => {
  const config = useSelector(selectCustomerDetailSelector);
  const navigate = useNavigate();
  const { theme, mobile, tablet, laptop, desktop, mainWidth } =
    responsiveBreakpoints();
  return (
    <AppBar
      position='static'
      sx={[
        {
          // position: 'sticky',
          // top: '31px',
          // top: `${
          //   !isEmpty(welcomeBarConfig) && welcomeBarConfig.wel_bar_pos == 1
          //     ? `${parseInt(welcomeBarConfig.bar_height) + 31}px`
          //     : '31px'
          // }`,
          height: '82px',
          backgroundColor: 'var(--paper-background-color)',
          boxShadow: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '0px',
          // zIndex: zIndex.stickyH2,
          borderBottom: '1px solid var(--primary-border-color)',
        },
      ]}
    >
      <Toolbar
        disableGutters
        sx={{
          justifyContent: 'space-between',
          width: mainWidth,
        }}
      >
        <Tooltip
          title={config?.logo_alt_text}
          placement='right-end'
          sx={{
            backgroundColor: 'red',
            color: 'red',
          }}
        >
          <a
            onClick={() => {
              navigate('/');
              location.reload();
            }}
            style={{ cursor: 'pointer' }}
          >
            {!isEmpty(config) && (
              <img
                src={config.media.logo}
                alt='logo'
                style={{ maxWidth: '300px', maxHeight: '50px' }}
              />
            )}
          </a>
        </Tooltip>

        <Stack
          direction='row'
          alignItems='center'
          spacing={2}
          sx={{ position: 'relative' }}
        >
          {config?.new_account_text_button ? (
            <Button
              variant='outlined'
              sx={{ padding: '10px 26px' }}
              onClick={() => navigate(PublicScreen.register)}
            >
              {config?.new_account_text_button}
            </Button>
          ) : null}

          <Button
            variant='contained'
            sx={{ padding: '10px 26px' }}
            onClick={() => navigate(PublicScreen.login)}
          >
            Login
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default Header2Public;
