import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeDraftSuggestionSelector } from 'redux/selectors';
import { showSnackbar } from 'components/snackbar/actions';
import { useParams } from 'react-router-dom';
import { api } from 'api';
import {
  addProductAndQuantityToDraft,
  updateProductQuantityDraft,
  removeGridItemToActiveDraft,
  updateItemSingleSuggestion,
  updateProductQuantityModalCall,
} from 'pages/catalog/action';
import { loggedInUserSelector } from 'redux/selectors';
import { useURLSearchParams } from 'common-hooks/url-params-listener-hooks';
import isEmpty from 'lodash/isEmpty';

export function useCrudOperationCart({
  setCartActionLoader,
  setCartFlag,
  setSuggestionActionLoader,
  setSuggestionFlag,
}) {
  const dispatch = useDispatch();
  const { cl } = useURLSearchParams();
  const activeDraftSuggestion = useSelector(activeDraftSuggestionSelector);
  const userInfo = useSelector(loggedInUserSelector);
  let currentPath = window.location.pathname.split('/');
  const { id } = useParams();
  // =======================> Update Order On Elastic in Order Detail Page <====================
  const updateOrderOnElastic = async () => {
    try {
      if (userInfo?.elastic_syncing) {
        const res = await api.get(
          `order/updateOrderOnElastic/${parseInt(currentPath[2])}`
        );
      }
    } catch (err) {
      console.error(err);
    }
  };
  // ====================> Add To Cart <==============================
  const addToCart = async (payload) => {
    setCartActionLoader(true);
    let prepack_quantity = {};
    let sizewise_quantity = {};
    for (let key in payload.values) {
      if (key.split('|')[1]) {
        prepack_quantity[key] = payload.values[key];
      } else {
        sizewise_quantity[key] = payload.values[key];
      }
    }

    let updatedPayload = {
      item_id: payload.id,
      worksheet_collection_id: parseInt(activeDraftSuggestion.draft.id),
      prepack_quantity,
      sizewise_quantity,
      shop_collection_id: payload.collection_id
        ? payload.collection_id
        : parseInt(cl),
    };
    if (currentPath.some((x) => x === 'order-payment')) {
      updatedPayload = {
        item_id: payload.id,
        worksheet_collection_id: parseInt(currentPath[2]),
        id: payload.id,
        collection_id: payload.collection_id,
        prepack_quantity,
        sizewise_quantity,
        is_order: true,
        prepack_order_quantity: payload.values.prepack_order_quantity,
      };
    }
    if (currentPath.some((x) => x === 'suggestions')) {
      updatedPayload = {
        item_id: payload.id,
        worksheet_collection_id: parseInt(activeDraftSuggestion.draft.id),
        linesheet_id: parseInt(id),
        prepack_quantity,
        sizewise_quantity,
        shop_collection_id: payload.collection_id
          ? payload.collection_id
          : parseInt(cl),
      };
    }
    const res = await dispatch(
      addProductAndQuantityToDraft({ ...updatedPayload })
    );
    if (currentPath.some((x) => x === 'order-payment')) {
      if (res?.data?.status) {
        updateOrderOnElastic();
      }
    }
    if (!res?.data?.status) {
      setCartFlag(false);
      dispatch(
        showSnackbar({
          snackbarMessage: 'Unable to add this Product',
          type: 'error',
          snackbarAlign: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      );
    }
    setCartActionLoader(false);
    return res;
  };
  // ====================> Update Product Quantity <=======================
  const updateProductQuantity = async (payload) => {
    const updatedPayload = {
      ...payload,
      worksheet_collection_id: parseInt(activeDraftSuggestion.draft.id),
      warehouse_id: '',
      is_order: false,
    };

    const res = await dispatch(
      updateProductQuantityDraft({ ...updatedPayload })
    );

    if (res?.data?.status) {
      // console.log('success');
    }
  };
  // ===================> Remove From Cart <===========================
  const removeFromCart = async (payload, action = '') => {
    setCartActionLoader(true);
    let updatedPayload = {
      ...payload,
      worksheet_collection_id: activeDraftSuggestion.draft.id,
    };
    if (currentPath.some((x) => x === 'suggestions')) {
      updatedPayload = {
        ...payload,
        worksheet_collection_id: activeDraftSuggestion.draft.id,
        linesheet_id: parseInt(id),
      };
    }
    if (!isEmpty(action)) {
      updatedPayload.action = action;
    }
    const data = await dispatch(removeGridItemToActiveDraft(updatedPayload));
    if (!data?.status) {
      setCartFlag(true);
      dispatch(
        showSnackbar({
          snackbarMessage: 'Unable to remove this itme from cart',
          type: 'error',
          snackbarAlign: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      );
    }
    setCartActionLoader(false);
    return data;
  };

  // ===================> SUBMIT FORM <====================================
  function submit({ productId, colorId, product_quantity }) {
    if (productId)
      updateProductQuantity({
        product_id: parseInt(productId),
        product_color_id: parseInt(colorId),
        product_quantity: product_quantity,
      });
  }
  //======================> update Suggestion <============================
  const modifyItemSingleSuggestion = async (payload) => {
    setSuggestionActionLoader(true);
    if (!activeDraftSuggestion.suggestion.id) {
      dispatch(
        showSnackbar({
          snackbarMessage:
            'No suggestion selected. Please select or create a suggestion',
          type: 'error',
          snackbarAlign: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      );
      return;
    }

    const updatedPayload = {
      ...payload,
      lineSheetId: activeDraftSuggestion.suggestion.id,
    };

    const data = await dispatch(updateItemSingleSuggestion(updatedPayload));
    if (data && data.message !== 'Success') {
      setSuggestionFlag(updatedPayload.isSuggest === 1 ? false : true); // checking if user tried to add suggestion and it got failed so make it false
      dispatch(
        showSnackbar({
          snackbarMessage: 'Unable to Add This Suggestion',
          type: 'error',
          snackbarAlign: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      );
    }
    setSuggestionActionLoader(false);
  };
  // =========================> Update Products for Modal <=========================

  const updateProductFromModal = async (payload, draftOrder) => {
    setCartActionLoader(true);

    const updatedPayload = {
      ...payload,
      action: 'update',
      // type: 'add_item_to_worksheet',
    };
    let currentPath = window.location.pathname.split('/');
    updatedPayload.worksheet_collection_id = currentPath.some(
      (x) => x === 'order-payment'
    )
      ? parseInt(currentPath[2])
      : activeDraftSuggestion.draft.id;
    /*updatedPayload.worksheet_collection_id = parseInt(
      activeDraftSuggestion.draft.id
    );*/

    const res = await dispatch(
      updateProductQuantityModalCall({ ...updatedPayload })
    );
    setCartActionLoader(false);
    return res;
  };

  //remove product from order
  const removeProduct = async (productId, selectedWorksheet, callback) => {
    const res = await api.post(`/removeItemFromDraft`, {
      item_id: productId,
      worksheet_id: selectedWorksheet?.worksheetCollectionId,
      is_order: true,
      replenish_inv: false,
    });

    if (res?.data?.status) {
      if (callback) {
        callback({ showLoader: true });
      }
    }

    return res;
  };

  return {
    addToCart,
    submit,
    removeFromCart,
    updateProductQuantity,
    modifyItemSingleSuggestion,
    updateProductFromModal,
    removeProduct,
  };
}
