import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../../api';
import { useDispatch } from 'react-redux';
import { getActiveDraftSuggestion } from '../../redux/actions/global-actions';
import { getSuggestions } from './actions';
import { SET_UPDATE_SUGGESTION_DETAILS } from 'redux/actions';

export default function useCustomSuggestion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get suggestions
  async function fetchSuggestion() {
    const res = await dispatch(getSuggestions({ pageNumber: '1' }));
    return res;
  }
  // create suggestion
  async function createSuggestion(name, description, setOpen) {
    const res = await api.post(`/createSuggestion`, {
      name: name,
      description: description,
    });

    if (res.data.status) {
      dispatch(getActiveDraftSuggestion());
      navigate(`/suggestions/${res.data.suggestionId}?resetCatalog=true`);
      //close quick panel
      setOpen(false);
    }
  }

  //delete suggestion
  async function deleteSuggestion(id, setOpen) {
    const res = await api.delete(`/deleteSuggestion?id=${id}`);
    if (res.data.status) {
      //updateURLParams({ resetCatalog: true });
      dispatch(getActiveDraftSuggestion());
      dispatch({
        type: SET_UPDATE_SUGGESTION_DETAILS,
        payload: { id: id, action: 'delete' },
      });
      let suggestionData = await fetchSuggestion();

      if (suggestionData.status) {
        navigate(`/suggestions/${suggestionData.data[0].id}`);
      }
    }
  }

  //bulk delete suggestions
  async function bulkDeleteSuggestions(ids, setOpen) {
    const res = await api.delete(`/bulkdeleteSuggestions`, {
      data: { id: ids },
    });
    if (res.data.status) {
      //updateURLParams({ resetCatalog: true });
      dispatch(getActiveDraftSuggestion());
      let suggestionData = await fetchSuggestion();

      if (suggestionData.status) {
        navigate(`suggestions/${suggestionData.data[0].id}`);
      }
    }
  }

  //set active suggestion
  async function setActiveSuggestion(id) {
    const res = await api.get(`updateActiveSuggestion?linesheet_id=${id}`);
    if (res.data.status) {
      fetchSuggestion();
      dispatch(getActiveDraftSuggestion());
    }
  }

  return {
    createSuggestion,
    deleteSuggestion,
    setActiveSuggestion,
    bulkDeleteSuggestions,
  };
}
