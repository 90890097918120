import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { responsiveBreakpoints } from 'utils/utils';
import TextField from '../../text-field';
import Textarea from '../../textarea';
import MyModal from 'components/modals/modal';
import MyRadio from '../../radio';
import { buyerFieldsName } from 'constants/buyer';
import { addBuyerSchema } from './validation-schema';
import { selectClientOptionsSelector } from 'redux/selectors';
import SelectOld from 'components/select/select.old';
import { getStateData } from 'utils/utils';
import { getCityData } from 'utils/utils';
import { getCountryData } from 'utils/utils';

const AddNewBuyerModal = ({
  open,
  setOpen,
  handleAddBuyerClick,
  handleCheckBuyerUserName,
  modalTitle,
  buyer,
  isViewOnly,
  id,
  countryOptions,
  edit,
  isNotificationAction,
  handleDeclineBtnClick,
}) => {
  const {
    name,
    username,
    title,
    email,
    businessPhone,
    mobilePhone,
    businessFax,
    street,
    city,
    state,
    zip,
    note,
  } = buyerFieldsName;

  const { mobile, tablet } = responsiveBreakpoints();

  const [status, setStatus] = useState(true);
  const clientOptions = useSelector(selectClientOptionsSelector);
  const [countryOpt, setCountryOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);

  useEffect(() => {
    getCountryDataFunc();
    if (buyer?.country) {
      let payload = {
        country: buyer?.country,
      };
      getStateDataFunc(payload);
    }
    if (buyer?.state) {
      let payload = {
        country: buyer?.country,
        state: buyer?.state,
      };
      getCityDataFunc(payload);
    }
  }, []);

  const getCountryDataFunc = async () => {
    let data = await getCountryData();
    setCountryOpt(data?.data?.data);
  };
  const getStateDataFunc = async (payload) => {
    let data = await getStateData({ payload });
    setStateOpt(data?.data?.data);
  };
  const getCityDataFunc = async (payload) => {
    const data = await getCityData({ payload });
    setCityOpt(data?.data?.data);
  };

  useEffect(() => {
    if (buyer && 'isActive' in buyer) {
      setStatus(buyer.isActive);
    } else if (buyer && 'status' in buyer) {
      setStatus(buyer.status);
    } else {
      setStatus(true);
    }
  }, [buyer]);

  const close = (
    <Button variant='outlined' color='secondary' onClick={() => setOpen(false)}>
      Close
    </Button>
  );

  const decline = (
    <Button
      variant='outlined'
      color='error'
      onClick={(e) => {
        e.preventDefault();
        handleDeclineBtnClick();
        setOpen(false);
      }}
    >
      Decline
    </Button>
  );

  const addBuyer = (
    <Button variant='contained' type='submit' form='add-new-buyer-form'>
      {isNotificationAction ? 'Approve' : edit ? 'Save Changes' : 'Add Buyer'}
    </Button>
  );

  return (
    <MyModal
      open={open}
      setOpen={setOpen}
      size='large'
      title={modalTitle}
      showCloseIcon
      actions={
        isViewOnly ? [] : [close, isNotificationAction && decline, addBuyer]
      }
      sx={{ width: mobile || tablet ? '100%' : '780px', height: '70vh' }}
    >
      <Formik
        validateOnMount
        validationSchema={addBuyerSchema}
        initialValues={{
          name: buyer?.name || buyer?.buyerName,
          username: buyer?.username,
          title: buyer?.title,
          status: status,
          country: buyer?.country ?? '',
          emailEngine: buyer?.emailEngine,
          email: buyer?.email,
          businessPhone: buyer?.businessPhone,
          businessFax: buyer?.businessFax,
          street: buyer?.street,
          mobilePhone: buyer?.mobilePhone,
          city: buyer?.city ?? '',
          state: buyer?.state ?? '',
          zip: buyer?.zip,
          note: buyer?.note || '',
        }}
        onSubmit={(values) => {
          let payload;
          if (edit) {
            payload = {
              buyerId: buyer?.user_id || buyer?.id,
              name: values.name,
              username: values.username,
              title: values.title,
              status: status,
              email: values.email,
              businessPhone: values.businessPhone,
              mobilePhone: values.mobilePhone,
              businessFax: values.businessFax,
              street: values.street,
              city: values.city,
              state: values.state,
              zip: values.zip,
              country: values.country,
              emailEngine: values.emailEngine || '',
              note: values.note,
            };
          } else {
            payload = {
              retailerId: id,
              name: values.name,
              username: values.username,
              title: values.title,
              status: status,
              email: values.email,
              businessPhone: values.businessPhone,
              mobilePhone: values.mobilePhone,
              businessFax: values.businessFax,
              street: values.street,
              city: values.city,
              state: values.state,
              zip: values.zip,
              country: values.country,
              emailEngine: values.emailEngine || '',
              note: values.note,
            };
          }

          handleAddBuyerClick(payload, buyer?.user_id || buyer?.id, setOpen);
        }}
      >
        {({ handleSubmit, setFieldError, ...rest }) => (
          <form id='add-new-buyer-form' onSubmit={handleSubmit}>
            <Grid container columnSpacing={2} rowSpacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  id={name}
                  name={name}
                  required
                  label='Name'
                  placeholder='Name'
                  value={rest.values.name}
                  onChange={rest.handleChange}
                  error={rest.touched.name && Boolean(rest.errors.name)}
                  helperText={rest.touched.name && rest.errors.name}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id={username}
                  name={username}
                  required
                  label='Username'
                  placeholder='Username'
                  value={rest.values.username}
                  onChange={(e) => {
                    rest.setFieldValue(
                      username,
                      e.target.value.replace(/[^a-zA-Z0-9-_@.]/g, '')
                    );
                  }}
                  onPaste={(e) => {
                    rest.setFieldValue(
                      username,
                      e.target.value.replace(/[^a-zA-Z0-9-_@.]/g, '')
                    );
                  }}
                  onBlur={(e) => {
                    rest.handleBlur(e);
                    handleCheckBuyerUserName(
                      rest.values.username,
                      setFieldError
                    );
                  }}
                  error={rest.touched.username && Boolean(rest.errors.username)}
                  helperText={rest.touched.username && rest.errors.username}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id={title}
                  name={title}
                  label='Title'
                  placeholder='Title'
                  value={rest.values.title}
                  onChange={rest.handleChange}
                  error={rest.touched.title && Boolean(rest.errors.title)}
                  helperText={rest.touched.title && rest.errors.title}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyRadio
                  onChange={setStatus}
                  defaultValue={status}
                  disabled={isViewOnly}
                  value={status}
                  row
                  label='Status'
                  options={[
                    { label: 'Active', value: true },
                    { label: 'Inactive', value: false },
                  ]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id={email}
                  name={email}
                  required
                  label='Email'
                  placeholder='Email'
                  value={rest.values.email}
                  onChange={rest.handleChange}
                  error={rest.touched.email && Boolean(rest.errors.email)}
                  helperText={rest.touched.email && rest.errors.email}
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id={businessPhone}
                  name={businessPhone}
                  label='Business Phone'
                  placeholder='Business Phone'
                  value={rest.values.businessPhone}
                  onChange={rest.handleChange}
                  error={
                    rest.touched.businessPhone &&
                    Boolean(rest.errors.businessPhone)
                  }
                  helperText={
                    rest.touched.businessPhone && rest.errors.businessPhone
                  }
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id={mobilePhone}
                  name={mobilePhone}
                  label='Mobile Phone'
                  placeholder='Mobile Phone'
                  value={rest.values.mobilePhone}
                  onChange={rest.handleChange}
                  error={
                    rest.touched.mobilePhone && Boolean(rest.errors.mobilePhone)
                  }
                  helperText={
                    rest.touched.mobilePhone && rest.errors.mobilePhone
                  }
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id={businessFax}
                  name={businessFax}
                  label='Business Fax'
                  placeholder='Business Fax'
                  value={rest.values.businessFax}
                  onChange={rest.handleChange}
                  error={
                    rest.touched.businessFax && Boolean(rest.errors.businessFax)
                  }
                  helperText={
                    rest.touched.businessFax && rest.errors.businessFax
                  }
                  disabled={isViewOnly}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant='h2'
                  sx={{
                    borderBottom: '1px solid var(--secondary-border-color)',
                    paddingBottom: '2px',
                  }}
                >
                  Contact Details
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id={street}
                  name={street}
                  label='Street Address'
                  placeholder='Enter Street Address'
                  value={rest.values.street}
                  onChange={
                    clientOptions.additionalSettings?.csc_dropdown == 0
                      ? (e) => {
                          if (
                            e.target.value.length <=
                            clientOptions.additionalSettings
                              ?.street_character_length
                          ) {
                            rest.setFieldValue(street, e.target.value);
                            setFieldError(street, '');
                            rest.setFieldTouched(street, true);
                          } else {
                            setFieldError(
                              street,
                              `Address Line 1 cannot exceed ${clientOptions.additionalSettings?.street_character_length} characters`
                            );
                          }
                        }
                      : rest?.handleChange
                  }
                  error={rest.touched.street && Boolean(rest.errors.street)}
                  helperText={rest.touched.street && rest.errors.street}
                  disabled={isViewOnly}
                />
              </Grid>

              <Grid
                container
                sx={{ padding: '16px 0px 23px 20px' }}
                columnSpacing={3}
                rowSpacing={2}
              >
                <Grid item xs={12} sm={6}>
                  {clientOptions.additionalSettings?.csc_dropdown == 0 ? (
                    <TextField
                      id={city}
                      name={city}
                      label='City'
                      placeholder='City'
                      value={rest.values.city}
                      onChange={(e) => {
                        if (
                          e.target.value.length <=
                          clientOptions.additionalSettings
                            ?.city_character_length
                        ) {
                          rest.setFieldValue(city, e.target.value);
                          setFieldError(city, '');
                          rest.setFieldTouched(city, true);
                        } else {
                          setFieldError(
                            city,
                            `City name cannot exceed ${clientOptions.additionalSettings?.city_character_length} characters`
                          );
                        }
                      }}
                      error={rest.touched.city && Boolean(rest.errors.city)}
                      helperText={rest.touched.city && rest.errors.city}
                      disabled={isViewOnly}
                    />
                  ) : (
                    <SelectOld
                      sx={{
                        maxWidth: '350px',
                      }}
                      label='Country'
                      placeholder='Enter Country'
                      id='country'
                      name='country'
                      value={{ label: rest?.values?.country }}
                      showOptionLabel
                      setFormikValue={(id, option) => {
                        if (rest.values['country'] !== option?.value) {
                          rest.setFieldValue(id, option?.value);
                          let payload = {
                            country: option?.value,
                          };
                          getStateDataFunc(payload);
                        }
                      }}
                      error={
                        rest?.touched?.country && Boolean(rest?.errors?.country)
                      }
                      helperText={
                        rest?.touched?.country && rest?.errors?.country
                      }
                      allowRemoveIcon={false}
                      options={(() => {
                        var arr = [];
                        countryOpt.forEach((option) => {
                          arr.push({
                            label: option.name,
                            value: option.code,
                          });
                        });
                        return arr;
                      })()}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  {clientOptions.additionalSettings?.csc_dropdown == 0 ? (
                    <TextField
                      id={state}
                      name={state}
                      label='State / Province'
                      placeholder='State / Province'
                      value={rest.values.state}
                      onChange={(e) => {
                        if (
                          e.target.value.length <=
                          (clientOptions.additionalSettings?.state_length == 0
                            ? clientOptions.additionalSettings
                                ?.state_character_length
                            : clientOptions.additionalSettings?.state_length)
                        ) {
                          rest.setFieldValue(state, e.target.value);
                          setFieldError(state, '');
                          rest.setFieldTouched(state, true);
                        } else {
                          setFieldError(
                            state,
                            `State / Province name cannot exceed ${
                              clientOptions.additionalSettings?.state_length ==
                              0
                                ? clientOptions.additionalSettings
                                    ?.state_character_length
                                : clientOptions.additionalSettings?.state_length
                            } characters`
                          );
                        }
                      }}
                      error={rest.touched.state && Boolean(rest.errors.state)}
                      helperText={rest.touched.state && rest.errors.state}
                      disabled={isViewOnly}
                    />
                  ) : (
                    <SelectOld
                      sx={{
                        maxWidth: '350px',
                      }}
                      label='State / Province'
                      placeholder='Select State / Province'
                      id='state'
                      name='state'
                      value={{ label: rest?.values?.state }}
                      showOptionLabel
                      setFormikValue={(id, option) => {
                        if (rest.values['state'] !== option?.value) {
                          rest.setFieldValue(id, option?.value);
                          let payload = {
                            country: rest.values['country'],
                            state: option?.value,
                          };
                          getCityDataFunc(payload);
                        }
                      }}
                      error={
                        rest?.touched?.state && Boolean(rest?.errors?.state)
                      }
                      helperText={rest?.touched?.state && rest?.errors?.state}
                      allowRemoveIcon={false}
                      options={(() => {
                        var arr = [];
                        stateOpt.forEach((option) => {
                          arr.push({
                            label: option.name,
                            value: option.code,
                          });
                        });
                        return arr;
                      })()}
                    />
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    id={zip}
                    name={zip}
                    label='Zip / Postal Code'
                    placeholder='Zip / Postal Code'
                    value={rest.values.zip}
                    onChange={rest.handleChange}
                    error={rest.touched.zip && Boolean(rest.errors.zip)}
                    helperText={rest.touched.zip && rest.errors.zip}
                    disabled={isViewOnly}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {clientOptions.additionalSettings?.csc_dropdown == 0 ? (
                    <TextField
                      required
                      id='country'
                      name='country'
                      label='Country'
                      placeholder='Enter Country'
                      value={rest?.values?.country}
                      onChange={(e) => {
                        if (
                          e.target.value.length <=
                          (clientOptions.additionalSettings?.country_length == 0
                            ? clientOptions.additionalSettings
                                ?.country_character_length
                            : clientOptions.additionalSettings?.country_length)
                        ) {
                          rest.setFieldValue('country', e.target.value);
                          setFieldError('country', '');
                          if (e.target.value.length > 1) {
                            rest.setFieldTouched('country', true);
                          }
                        } else {
                          setFieldError(
                            'country',
                            `Country name cannot exceed ${
                              clientOptions.additionalSettings
                                ?.country_length == 0
                                ? clientOptions.additionalSettings
                                    ?.country_character_length
                                : clientOptions.additionalSettings
                                    ?.country_length
                            } characters`
                          );
                        }
                      }}
                      error={
                        rest?.touched?.country && Boolean(rest?.errors?.country)
                      }
                      helperText={
                        rest?.touched?.country && rest?.errors?.country
                      }
                      disabled={isViewOnly}
                    />
                  ) : (
                    <SelectOld
                      sx={{
                        maxWidth: '350px',
                      }}
                      label='City'
                      id='city'
                      name='city'
                      placeholder='Enter City'
                      value={{ label: rest?.values?.city }}
                      showOptionLabel
                      setFormikValue={(id, option) => {
                        rest.setFieldValue(id, option?.value);
                      }}
                      error={rest?.touched?.city && Boolean(rest?.errors?.city)}
                      helperText={rest?.touched?.city && rest?.errors?.city}
                      allowRemoveIcon={false}
                      options={(() => {
                        var arr = [];
                        cityOpt.forEach((option) => {
                          arr.push({
                            label: option.name,
                            value: option.name,
                          });
                        });
                        return arr;
                      })()}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography
                  variant='h2'
                  sx={{
                    borderBottom: '1px solid var(--secondary-border-color)',
                    paddingBottom: '2px',
                  }}
                >
                  Other Details
                </Typography>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <Select
                  required
                  variant='outlined'
                  color='secondary'
                  size='medium'
                  label='Email Engine'
                  placeholder='Email Engine'
                  value={
                    countryOptions.find(
                      (c) => c.value == rest.values.emailEngine
                    ) || countryOptions[0]
                  }
                  setValue={(value) =>
                    rest.setFieldValue(country, value?.value, true)
                  }
                  options={countryOptions}
                />
              </Grid> */}
              <Grid item xs={12} sm={12}>
                <Textarea
                  id={note}
                  name={note}
                  label='Note'
                  rows={4}
                  value={rest.values.note}
                  onChange={rest.handleChange}
                  disabled={isViewOnly}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </MyModal>
  );
};

AddNewBuyerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleAddBuyerClick: PropTypes.func.isRequired,
  handleCheckBuyerUserName: PropTypes.func,
  modalTitle: PropTypes.string,
  buyer: PropTypes.object,
  isViewOnly: PropTypes.bool,
  id: PropTypes.any,
  countryOptions: PropTypes.array.isRequired,
  edit: PropTypes.bool.isRequired,
  isNotificationAction: PropTypes.bool,
  handleDeclineBtnClick: PropTypes.func,
};

AddNewBuyerModal.defaultProps = {
  handleCheckBuyerUserName: () => {},
  modalTitle: 'Add New Buyer',
  isViewOnly: false,
  id: null,
  edit: false,
  isNotificationAction: false,
  handleDeclineBtnClick: () => {},
  buyer: {},
};

export default AddNewBuyerModal;
