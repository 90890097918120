import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { activeDraftSuggestionSelector } from 'redux/selectors';
import { loggedInUserSelector } from 'redux/selectors';
import { getReceivedSuggestions } from './actions';
import { useQuery } from 'react-query';
import { profileRoles } from 'constants/index';

export default function useSuggestions() {
  const [showNewSuggestionModal, setShowNewSuggestionModal] = useState(false);
  const [selected, setSelected] = useState('suggestions');
  const [pageNumber, setPageNumber] = useState(1);
  const dispatch = useDispatch();
  const userConfig = useSelector(loggedInUserSelector);
  const ROLE_ID = userConfig.roleId;
  const activeDraftSuggestion = useSelector(activeDraftSuggestionSelector);
  useEffect(() => {
    if (ROLE_ID == profileRoles?.buyer) {
      setSelected('sharedSuggestions');
    }
  }, [ROLE_ID]);
  const {
    data: receivedSuggestionsData,
    isLoading: receivedSuggestionsLoading,
    ...rest
  } = useQuery(
    ['getReceivedSuggestions', activeDraftSuggestion.suggestion.id],
    () => dispatch(getReceivedSuggestions({ pageNumber: pageNumber })),
    {
      cacheTime: 3600000,
      staleTime: 3600000,
      select: (data) => {
        return data;
      },
    }
  );

  return {
    showNewSuggestionModal,
    setShowNewSuggestionModal,
    selected,
    setSelected,
    pageNumber,
    setPageNumber,
    receivedSuggestionsData,
    receivedSuggestionsLoading,
    ROLE_ID,
  };
}
