import React, { useRef, useState, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import WarningIcon from '@mui/icons-material/Warning';
import Info from '@mui/icons-material/Info';

import { useProductCardVariant } from './product-card-variant-hooks';
import no_image from '../../../assets/images/no_image.png';
import no_image_cdn from '../../../assets/images/no_image_cdn.png';
import SuggestionIcon from '../../../assets/icons/suggestion-icon';
import CartIcon from '../../../assets/icons/cart-icon';
import SizeTable from './product-size-table';
import './product-card-variant.css';
import RemoveIcon from '../../../assets/icons/remove-icon';
import GalleryIcon from '../../../assets/icons/gallery-icon';
import LazyImage from 'components/lazy-image/lazy-image';
import { Formik } from 'formik';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import ProductListItem from '../../../components/modals/add-products/components/product-list-item';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { useCrudOperationCart } from 'common-hooks/crud-operation-cart-hooks';
import { Divider } from '@mui/material';

import LightBulbOffIcon from 'assets/icons/light-bulb-off-icon';
import LightBulbOnIcon from 'assets/icons/light-bulb-on-icon';
import LightBulbIcon from '../../../assets/icons/light-bulb-icon';
import isEmpty from 'lodash/isEmpty';
import PriceTier from 'components/price-tier';
import { catalogSendButton } from './catalog-send-button/catalog-send-button';
import { catalogRequestInfoHooks } from './catalog-request-info/catalog-request-info-hooks';
import CatalogRequestInfo from './catalog-request-info/catalog-request-info';
import HeaderCartIcon from 'assets/icons/header-cart-icon';
import { LogLevel } from 'constants';
import LoggingService from 'utils/service/LoggingService';

const ProductDescriptionModal = lazy(() => import('./productDetailModal'));

const ProductCardVariant = ({
  product,
  ROLE_ID,
  userCurrencySymbol,
  aspectRatio,
  isImageEditedByClient,
  showCollectionName,
  isSuggestion,
  removeProductFromSuggestion,
  showSuggestionIcon,
  showAddToCartButton,
}) => {
  const {
    cartFlag,
    setCartFlag,
    suggestionFlag,
    setSuggestionFlag,
    calculateItemTotalAndQuantity,
    validateFormik,
    quantityErrorFlag,
    setQuantityErrorFlag,
    itemCalculation,
    setItemCalculation,
    cartActionLoader,
    setCartActionLoader,
    suggestionActionLoader,
    setSuggestionActionLoader,
    productModalFlag,
    setProductModalFlag,
    hovered,
    setHovered,
  } = useProductCardVariant({ product });
  const { addToCart, submit, removeFromCart, modifyItemSingleSuggestion } =
    useCrudOperationCart({
      setCartActionLoader,
      setCartFlag,
      setSuggestionActionLoader,
      setSuggestionFlag,
    });

  const [initialValues, setInitialValues] = useState(
    (() => {
      try {
        var initValues = {};

        product?.item_colors?.map((color) => {
          if (color?.sizes) {
            color?.sizes?.forEach((size) => {
              initValues[`${size?.sizeCombinationId}`] = size?.sizeQuantity;
            });
          }

          if (color?.dimensions) {
            color?.dimensions?.forEach((dimension) => {
              dimension?.sizes?.forEach((size) => {
                initValues[`${size.sizeCombinationId}`] = size.sizeQuantity;
              });
            });
          }

          if (
            color?.prePackSizes &&
            Object.keys(color?.prePackSizes).length > 0
          ) {
            color?.prePackSizes.forEach((prepackSize) => {
              if (Array.isArray(prepackSize)) {
                let isPackQuantitySet = false;
                prepackSize.forEach((size) => {
                  if (size.packQuantity > 0 && !isPackQuantitySet) {
                    initValues[`${size.prePackId}`] = size.packQuantity;
                    isPackQuantitySet = true;
                  }
                });
              }
            });
          }
        });
        return initValues;
      } catch (err) {
        LoggingService.log(
          'useState1',
          'ProductCardVariant',
          err,
          LogLevel.ERROR
        );
      }
    })()
  );

  const [isExpanded, setIsExpanded] = useState(false);

  // useEffect(() => {
  //   initializeFormikValues();
  // }, [product]);
  //  function to initialize values
  const initializeFormikValues = (values) => {
    try {
      // inital values for Size Table Formik
      var initValues = {};

      product?.item_colors?.map((color) => {
        if (color?.sizes) {
          color?.sizes?.forEach((size) => {
            // initValues[`${size?.sizeCombinationId}`] = size?.sizeQuantity;
            initValues[`${size?.sizeCombinationId}`] =
              values[`${size?.sizeCombinationId}`];
            // cartFlag || cartFlagInitialize
            //   ? //  if CartFlag is true. then assign the formik values to the new formikID fields since we are not refetching the products
            //     (initValues[`regular_${size?.sizeId}_${color?.colorId}`] =
            //       formRefvalues
            //         ? formRefvalues[`${size?.sizeCombinationId}`]
            //         : size?.sizeQuantity)
            //   : (initValues[`${size?.sizeCombinationId}`] = size?.sizeQuantity);
          });
        }

        if (color?.dimensions) {
          color?.dimensions?.forEach((dimension) => {
            dimension?.sizes?.forEach((size) => {
              // initValues[`${size.sizeCombinationId}`] = size.sizeQuantity;
              initValues[`${size.sizeCombinationId}`] =
                values[`${size.sizeCombinationId}`];
              // cartFlag || cartFlagInitialize
              //   ? (initValues[
              //       `dimension_${size.sizeId}_${color?.colorId}_${size.sizeTableRowCounter}`
              //     ] = formRefvalues
              //       ? formRefvalues[`${size?.sizeCombinationId}`]
              //       : size.sizeQuantity)
              //   : (initValues[`${size.sizeCombinationId}`] = size.sizeQuantity);
            });
          });
        }

        if (
          color?.prePackSizes &&
          Object.keys(color?.prePackSizes).length > 0
        ) {
          color?.prePackSizes.forEach((prepackSize) => {
            if (Array.isArray(prepackSize)) {
              let isPackQuantitySet = false;
              prepackSize.forEach((size) => {
                if (size.packQuantity > 0 && !isPackQuantitySet) {
                  // initValues[`${size.prePackId}`] = size.packQuantity;
                  initValues[`${size.prePackId}`] = values[`${size.prePackId}`];
                  isPackQuantitySet = true;
                }
              });
            }
            // if (i === 0) {
            //   cartFlag || cartFlagInitialize
            //     ? (initValues[
            //         `prepack_${size.sizeTableSizeId}_${size.packId}_${size.prePackSizeRow}_${size.prePackId}`
            //       ] = formRefvalues
            //         ? formRefvalues[`${size.prePackId}`]
            //         : size.packQuantity)
            //     : (initValues[`${size.prePackId}`] = size.packQuantity);
            // }
          });
        }
      });

      setInitialValues(initValues);
    } catch (err) {
      LoggingService.log(
        'initializeFormikValues',
        'ProductCardVariant',
        err,
        LogLevel.ERROR
      );
    }
  };

  const formRef = useRef();

  let sendButtonType = 'list';
  let perRow = 1;
  let { buttonText } = catalogSendButton({
    sendButtonType,
    product,
    cartFlag,
    perRow,
  });

  let {
    openRequestInfoModal,
    setOpenRequestInfoModal,
    confirmRequestInfo,
    requestInfoTitle,
    sendRequestInfoEmail,
  } = catalogRequestInfoHooks({
    ROLE_ID,
  });

  // cdn broken image logic
  let error_image = product?.productImageLinkTag ? no_image_cdn : no_image;
  return (
    <>
      <Card
        elevation={0}
        sx={[
          {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'top',
            width: '100%',
            position: 'relative',
            // border: '1px solid var(--secondary-border-color)',
            paddingRight: '27px',
          },
          cartFlag
            ? {
                border: '2px solid',
                borderColor: 'primary.main',
              }
            : null,
        ]}
      >
        <CardActions
          sx={[
            {
              padding: '0 0 0 0',
              width: '200px',
              // display: 'flex',
              justifyContent: 'right',
              // alignItems: 'flex-start',
              position: 'absolute',
              top: '40px',
              right: '26px',
              zIndex: '1',
            },
            {
              '& .MuiSvgIcon-fontSizeMedium': {
                fontSize: '14px',
              },
            },
          ]}
        >
          <Stack direction='row' spacing={1}>
            {ROLE_ID !== '3' && showSuggestionIcon ? (
              <IconButton
                onClick={() => {
                  setSuggestionFlag((prev) => !prev);
                  setHovered(false);
                  if (isSuggestion) {
                    removeProductFromSuggestion(product?.id);
                  } else {
                    modifyItemSingleSuggestion({
                      isSuggest: suggestionFlag ? 0 : 1,
                      productId: product?.id,
                    });
                  }
                }}
                onMouseOver={() => {
                  setHovered(true);
                }}
                onMouseOut={() => {
                  setHovered(false);
                }}
                onMouseEnter={() => {
                  setHovered(true);
                }}
                onMouseLeave={() => {
                  setHovered(false);
                }}
                disabled={suggestionActionLoader}
                sx={[
                  {
                    padding: '5px 5px',
                    borderRadius: '4px',
                    height: '29px',
                  },
                  suggestionFlag
                    ? {
                        padding: '5px',
                        borderRadius: '20px',
                        background: '#ef9c002b',
                      }
                    : hovered
                    ? {
                        backgroundColor: '#f5f5f5', // Light background color
                        transition: 'background-color .2s',
                        borderRadius: '50%',
                        '&:hover': {
                          backgroundColor: '#e0e0e0', // Darker background color on hover
                        },
                      }
                    : {
                        paddingRight: '5px',
                      },
                ]}
              >
                {suggestionFlag ? (
                  <LightBulbOnIcon
                    fill='var(--theme-color)'
                    width='20'
                    height='20'
                  />
                ) : hovered ? (
                  <LightBulbIcon width='20' height='20' />
                ) : (
                  <LightBulbOffIcon width='20' height='20' />
                )}
              </IconButton>
            ) : null}

            {!showAddToCartButton ? null : product.displayOnly ||
              !product.canAdd ? (
              <LoadingButton
                variant='outlined'
                loading={cartActionLoader}
                disabled={
                  quantityErrorFlag?.maxQuantity ||
                  quantityErrorFlag?.minQuantity
                }
                sx={{
                  height: '29px',
                  width: '120px',
                  borderColor: '#000',
                  color: '#000',
                  '&:hover': { borderColor: '#000' },
                }}
                onClick={(e) => {
                  if (product.displayOnly) {
                    setOpenRequestInfoModal(true);
                  } else {
                    e.preventDefault();
                  }
                }}
              >
                {buttonText}
              </LoadingButton>
            ) : cartFlag ? (
              <LoadingButton
                variant='contained'
                loading={cartActionLoader}
                disabled={cartActionLoader}
                startIcon={<RemoveIcon fill='#fff' />}
                onClick={() => {
                  setCartFlag(false);
                  for (const key in formRef.current.values) {
                    formRef.current.setFieldValue(key, 0);
                  }
                  removeFromCart({ item_id: product?.id }, 'delete');
                }}
                sx={{
                  height: '29px',
                  width: '120px',
                  backgroundColor: '#000',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#000',
                  },
                }}
              >
                {buttonText}
              </LoadingButton>
            ) : (
              <LoadingButton
                variant='outlined'
                loading={cartActionLoader}
                disabled={
                  quantityErrorFlag?.maxQuantity ||
                  quantityErrorFlag?.minQuantity
                }
                startIcon={
                  // <CartIcon
                  //   fill={
                  //     cartActionLoader ||
                  //     quantityErrorFlag?.maxQuantity ||
                  //     quantityErrorFlag?.minQuantity
                  //       ? '#cdccd6'
                  //       : '#000'
                  //   }
                  // />
                  <HeaderCartIcon
                    fill={
                      cartActionLoader ||
                      quantityErrorFlag?.maxQuantity ||
                      quantityErrorFlag?.minQuantity
                        ? '#cdccd6'
                        : '#000'
                    }
                  />
                }
                onClick={() => {
                  setCartFlag(true);
                  addToCart({
                    values: { ...(formRef.current.values || {}) },
                    id: product?.id,
                    collection_id: product.product_collection_id,
                  });
                  // initializeFormikValues(formRef.current.values, true);
                }}
                sx={{
                  height: '29px',
                  width: '120px',
                  borderColor: '#000',
                  color: '#000',
                  '&:hover': { borderColor: '#000' },
                }}
              >
                {buttonText}
              </LoadingButton>
            )}
          </Stack>
        </CardActions>
        <div
          style={{
            position: 'relative',
            width: '147.5px',
            overflow: 'hidden',
            paddingLeft: '13px',
            borderRight: '1px solid var(--secondary-border-color)',
          }}
        >
          {product ? (
            <img
              onClick={() => {
                setProductModalFlag(true);
              }}
              src={product?.productImage ? product?.productImage : error_image}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = error_image;
              }}
              style={{
                cursor: 'pointer',
                height: '90%',
                width: '100%',
                marginLeft: '-5px',
                objectFit: 'contain',
              }}
            />
          ) : (
            <LazyImage
              imageUrl={
                product.productImage ? product.productImage : error_image
              }
              alt={''}
            />
          )}

          {product?.in_other_collections ? (
            <Stack
              direction='row'
              spacing={0.5}
              sx={{
                alignItems: 'right',
                justifyContent: 'right',
                paddingRight: '5px',
                position: 'absolute',
                bottom: 8,
                right: 0,
              }}
            >
              <Typography
                variant='subtitle1'
                onClick={(e) => {
                  e.preventDefault();
                  setIsExpanded(!isExpanded);
                  //showCategoryOptions(group.group_name);
                }}
                // onMouseOver={(e) => {
                //   e.preventDefault();
                //   showCategoryOptions(group.group_name);
                // }}
                sx={{
                  float: 'center',
                  cursor: 'pointer',
                  fontSize: '10px',
                  display: 'flex',
                  alignItems: 'center',
                  paddingBottom: '5px',
                  color: '#1189F0',
                  //lineHeight: '14px',
                  padding: '5px',
                }}
              >
                Other Deliveries
                {isExpanded ? (
                  <KeyboardArrowUpOutlinedIcon
                    sx={{ position: 'relative', opacity: '0.5' }}
                  />
                ) : (
                  <KeyboardArrowDownOutlinedIcon
                    sx={{ position: 'relative', opacity: '0.5' }}
                  />
                )}
              </Typography>
            </Stack>
          ) : null}

          <Stack
            direction='row'
            spacing={0.5}
            sx={{
              alignItems: 'right',
              justifyContent: 'right',
              paddingRight: '5px',
            }}
          >
            <GalleryIcon sx={{ position: 'relative', top: '2px' }} />
            <Typography variant='body2'>
              {product?.totalImageCount == 0 ? 1 : product?.totalImageCount}
            </Typography>
          </Stack>
        </div>

        <Stack
          direction='column'
          sx={{
            width: '100%',
            overflowX: 'auto',
            paddingTop: '14.27px',
            paddingLeft: '25.5px',
            paddingBottom: '14.27px',
          }}
        >
          <Grid container>
            <Grid item xs={6}>
              <Stack direction='row' spacing={0.5}>
                <Typography
                  variant='body2'
                  sx={{
                    fontWeight: 700,
                    letterSpacing: '0.18px',
                    color: '#222222',
                  }}
                >
                  {product?.name} -
                </Typography>
                <Typography
                  variant='body2'
                  sx={{ color: '#333333', fontWeight: '500' }}
                >
                  {product?.style}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack
                direction='row'
                spacing={2}
                justifyContent='right'
                width='100%'
                style={product.displayOnly ? { visibility: 'hidden' } : null}
              >
                {/*{Add condition for both Catalog and Draft pages modal}*/}
                {product?.hasDiscount ||
                product?.productDiscount ||
                parseInt(product?.discount) > 0 ? (
                  <Typography variant='body2'>
                    <span style={{ fontWeight: 300 }}>Cost:</span>{' '}
                    <span
                      style={{
                        fontWeight: '500',
                        textDecoration: 'line-through',
                      }}
                    >
                      {product.userCurrencySymbol
                        ? product.userCurrencySymbol
                        : userCurrencySymbol}
                      {product?.cost}
                    </span>{' '}
                    /{' '}
                    <span
                      style={
                        product?.hasDiscount > 0 ||
                        product?.productDiscount ||
                        parseInt(product?.discount) > 0
                          ? { color: 'red', fontWeight: 700 }
                          : { color: '#222222', fontWeight: 700 }
                      }
                    >
                      {product.userCurrencySymbol
                        ? product.userCurrencySymbol
                        : userCurrencySymbol}
                      {product?.per_unit_price_display}
                    </span>
                  </Typography>
                ) : (
                  <Typography variant='body2'>
                    <span style={{ fontWeight: 300 }}>Cost:</span>{' '}
                    <span
                      style={
                        product?.hasDiscount > 0 || product?.productDiscount > 0
                          ? { color: 'red', fontWeight: 700 }
                          : {
                              fontWeight: 700,
                              color: '#222222',
                            }
                      }
                    >
                      {product.userCurrencySymbol
                        ? product.userCurrencySymbol
                        : userCurrencySymbol}
                      {product?.per_unit_price_display}
                    </span>
                  </Typography>
                )}

                {product?.msrp > 0 ? (
                  <Typography variant='body2'>
                    <span style={{ fontWeight: 300 }}>MSRP:</span>{' '}
                    <span
                      style={{
                        fontWeight: 700,
                        color: '#222222',
                      }}
                    >
                      {product.userCurrencySymbol
                        ? product.userCurrencySymbol
                        : userCurrencySymbol}
                      {product?.msrp}
                    </span>
                  </Typography>
                ) : (
                  ''
                )}
              </Stack>
            </Grid>
          </Grid>
          {/* minimum and maximum error UI */}
          <Stack
            direction='row'
            justifyContent='space-between'
            sx={{
              width: '100%',
              padding: '5px 0 0 0',
            }}
          >
            <Stack
              direction='column'
              spacing={0.5}
              sx={{ paddingBottom: '3px' }}
            >
              <Typography
                variant='body1'
                sx={{
                  color: 'var(--secondary-font-color)',
                }}
              >
                {product?.short_desc}
              </Typography>
              {showCollectionName ? (
                <Typography
                  variant='body1'
                  sx={{
                    color: 'var(--secondary-font-color)',
                  }}
                >
                  {product?.collection}
                </Typography>
              ) : null}
              {/* price Tier UI */}
              {!isEmpty(product?.price_tiers) && (
                <PriceTier
                  priceTiers={product?.price_tiers}
                  userCurrencySymbol={product?.userCurrencySymbol}
                />
              )}
              {parseInt(product?.maximumPerStyle) !== 0 ||
              parseInt(product?.minimumPerStyle) !== 0 ? (
                <Stack direction='column' spacing={0.5}>
                  {product?.minimumPerStyle &&
                  quantityErrorFlag?.minQuantity ? (
                    <Stack
                      direction='row'
                      spacing={1}
                      sx={{
                        backgroundColor: 'rgb(229, 246, 253)',
                        height: '28px',
                        width: 'fit-content',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'rgb(1, 67, 97)',
                        padding: '0 5px 0 5px',
                        borderRadius: '5px',
                      }}
                    >
                      <Info fontSize='small' />
                      <Typography
                        variant='body2'
                        sx={{ color: 'inherit' }}
                      >{`The minimum for this style is ${product?.minimumPerStyle} units`}</Typography>
                    </Stack>
                  ) : null}
                  {/* maximum error */}
                  {product?.maximumPerStyle &&
                  quantityErrorFlag?.maxQuantity ? (
                    <Stack
                      direction='row'
                      spacing={1}
                      sx={{
                        backgroundColor: 'rgb(229, 246, 253)',
                        height: '28px',
                        width: 'fit-content',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'rgb(1, 67, 97)',
                        padding: '0 5px 0 5px',
                        borderRadius: '5px',
                      }}
                    >
                      <Info fontSize='small' />
                      <Typography
                        variant='body2'
                        sx={{ color: 'inherit' }}
                      >{`The maximum for this style is ${product?.maximumPerStyle} units`}</Typography>
                    </Stack>
                  ) : null}
                </Stack>
              ) : null}
            </Stack>
          </Stack>

          <CatalogRequestInfo
            ROLE_ID={ROLE_ID}
            sendRequestInfoEmail={sendRequestInfoEmail}
            productId={product.id}
            openRequestInfoModal={openRequestInfoModal}
            setOpenRequestInfoModal={setOpenRequestInfoModal}
            confirmRequestInfo={confirmRequestInfo}
            requestInfoTitle={requestInfoTitle}
          />

          <Formik
            validateOnMount
            validationSchema=''
            initialValues={initialValues}
            enableReinitialize={true}
            innerRef={formRef}
            validate={validateFormik}
            // onSubmit={
            //   cartFlag &&
            //   !quantityErrorFlag.maxQuantity &&
            //   !quantityErrorFlag.minQuantity
            //     ? submit()
            //     : () => {}
            // }
          >
            {({ ...rest }) => {
              return product?.item_colors?.map((color, i) => {
                return (
                  <SizeTable
                    key={`${product?.id}_${i}`}
                    productDetails={product}
                    hideTableHead
                    colorName={color?.colorName}
                    colorHex={color?.colorHex}
                    colorCode={color?.colorCode}
                    sizes={color?.sizes}
                    dimensions={color?.dimensions}
                    prePackSizes={color?.prePackSizes}
                    productId={product?.id}
                    colorId={color?.colorId}
                    sizeTableId={product?.sizeTableSizeId}
                    prepackCode=''
                    cartFlag={cartFlag}
                    sizeTableRow='0'
                    // formRef={formRef}
                    costPerUnit={parseFloat(product?.per_unit_price)}
                    setQuantityErrorFlag={setQuantityErrorFlag}
                    quantityErrorFlag={quantityErrorFlag}
                    showInventory={product?.showInventory}
                    userCurrencySymbol={product?.userCurrencySymbol}
                    rest={rest}
                    validateFormik={validateFormik}
                    submit={submit}
                    color={color} // need this prop to send it to validate formik function
                    initialValues={initialValues}
                    colorIndex={i}
                    calculateItemTotalAndQuantity={
                      calculateItemTotalAndQuantity
                    }
                    itemCalculation={itemCalculation}
                    setItemCalculation={setItemCalculation}
                    isAvailableInOtherCollection={product?.in_other_collections}
                    isAllowNegativeInventory={
                      product?.isAllowNegative
                        ? product?.isAllowNegative
                        : false
                    }
                  />
                );
              });
            }}
          </Formik>
        </Stack>
      </Card>

      <Divider
        sx={{
          width: '100%',
          margin: '10px auto 10px auto',
          backgroundColor: '#eee',
        }}
      />

      {isExpanded && product?.in_other_collections ? (
        <Collapse in={isExpanded} timeout={600}>
          <Box
            elevation={0}
            sx={[
              {
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                position: 'relative',
                backgroundColor: 'var(--paper-background-color)',
                // border: '1px solid var(--secondary-border-color)',
              },
            ]}
          >
            <Stack sx={{ width: '100%', padding: '5px' }}>
              {product?.in_other_collections.map((product) => (
                <ProductListItem
                  key={product.product_id}
                  name={product.collection_name}
                  color={product.color}
                  deliverFrom={product.delivery_from}
                  description={product.collection_message}
                  image={product.image_file}
                  style={product.style}
                  id={product.product_id}
                  isOtherDeliveriesDisplay={true}
                  collectionId={product.id}
                  is_draft_product={
                    product?.is_draft_product
                      ? product?.is_draft_product
                      : false
                  }
                />
              ))}
            </Stack>
          </Box>
        </Collapse>
      ) : null}
      {productModalFlag ? (
        <Suspense fallback={<div>Loading...</div>}>
          <ProductDescriptionModal
            productModalFlag={productModalFlag}
            setProductModalFlag={setProductModalFlag}
            productDetails={product}
            cartFlag={cartFlag}
            setCartFlag={setCartFlag}
            suggestionFlag={suggestionFlag}
            setSuggestionFlag={setSuggestionFlag}
            sizeTableFormRef={formRef}
            reinitializeSizeTableValues={({ values }) => {
              let sizewise_quantity = {};
              for (let key in values) {
                if (key.split('|')[1]) {
                  sizewise_quantity[key] = values[key];
                } else {
                  var temp = key.split('_');
                  var val = temp.filter((item) => item !== 'size').join('_');
                  sizewise_quantity[val] = values[key];
                }
              }
              initializeFormikValues(sizewise_quantity);
            }}
          />
        </Suspense>
      ) : null}
    </>
  );
};

ProductCardVariant.propTypes = {
  product: PropTypes.object.isRequired,
  ROLE_ID: PropTypes.string,
  userCurrencySymbol: PropTypes.string,
  aspectRatio: PropTypes.string,
  isImageEditedByClient: PropTypes.bool,
  showCollectionName: PropTypes.bool,
  isSuggestion: PropTypes.bool,
  removeProductFromSuggestion: PropTypes.func,
  showSuggestionIcon: PropTypes.bool,
  showAddToCartButton: PropTypes.bool,
};

ProductCardVariant.defaultProps = {
  userCurrencySymbol: '$',
  aspectRatio: 'Square',
  isImageEditedByClient: false,
  showCollectionName: false,
  isSuggestion: false,
  removeProductFromSuggestion: () => {},
  showSuggestionIcon: true,
  showAddToCartButton: true,
};
export default React.memo(ProductCardVariant);
