import { useEffect, useState, useCallback } from 'react';
import { api } from '../../api';
import { useSelector, useDispatch } from 'react-redux';
import { selectCustomerDetailSelector } from 'redux/selectors';
import { loggedInUserSelector } from 'redux/selectors';
import { useParams } from 'react-router-dom';
import {
  suggestionEmailPayloadSelector,
  catalogPdfPayloadSelector,
  catalogLinkPayloadSelector,
  draftLinkPayloadSelector,
} from './selectors';
import { activeDraftSuggestionSelector } from 'redux/selectors';
import { useNavigate } from 'react-router-dom';
import SessionStorageManager from 'utils/session-storage-manager';
import { setCatalogLinkPayload } from './action';
import { setCatalogPdfPayload } from './action';
import { setSuggestionEmailPayload } from './action';
import { showSnackbar } from 'components/snackbar/actions';
import { snackbarTypes } from 'constants';
import { fileLimit } from './constant';
import { profileRoles } from 'constants';

export default function useSendEmail({ setOpen, worksheetCollectionId }) {
  // form state
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [selectedAssociates, setSelectedAssociates] = useState([]);
  const [selectedExternalContacts, setSelectedExternalContacts] = useState([]);
  const [subject, setSubject] = useState('');
  const [editorValue, setEditorValue] = useState([]);
  const [message, setMessage] = useState(' ');
  const [timeline, setTimeline] = useState(1);
  const [emailRequests, setEmailRequests] = useState([]);
  const [sendingEmails, setSendingEmails] = useState(false);
  const [sender, setSender] = useState({ label: 'Sales Rep', value: 'sr' });
  const [showInventory, setShowInventory] = useState(false);
  const client = useSelector(selectCustomerDetailSelector);
  const user = useSelector(loggedInUserSelector);
  //state for signature and sender for sendInvite email
  const [selectedSender, setSelectedSender] = useState('');
  const [selectedSignature, setSelectedSignature] = useState('');
  const [subjectEmptyError, setSubjectEmptyError] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showSendingEmailAlertModal, setShowSendingEmailAlertModal] =
    useState(false);
  const [isBuyersListExceeded, setIsBuyersListExceeded] = useState(false);
  const [isCopyAssociateListExceeded, setIsCopyAssociateListExceeded] =
    useState(false);
  const [isAllBuyerSelected, setIsAllBuyerSelected] = useState(false);
  const [groups, setGroups] = useState([]);
  const [salesRep, setSalesRep] = useState('');
  const [messageEmptyError, setMessageEmptyError] = useState(false);
  const [totalBuyersSelected, setTotalBuyersSelected] = useState(0);
  const [isSendActiveBuyerEmailChecked, setIsSendActiveBuyerEmailChecked] =
    useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [onFirstTimeLoad, setOnFirstTimeLoad] = useState(true);
  const [previewMessage, setPreviewMessage] = useState('');
  const [previewSubject, setPreviewSubject] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadFileLoader, setUploadFileLoader] = useState(false);
  const [uploadImageLoader, setUploadImageLoader] = useState(false);
  const [getPreviewLoader, setGetPreviewLoader] = useState(false);
  const [isError, setIsError] = useState({ open: false, message: '' });
  const [isCopyMeSelected, setIsCopyMeSelected] = useState(false);
  const [showSuggestionWarningModal, setShowSuggestionWarningModal] =
    useState(false);
  const suggestionEmailpayloadData = useSelector(
    suggestionEmailPayloadSelector
  );
  const catalogPdfPayloadData = useSelector(catalogPdfPayloadSelector);
  const catalogLinkPayloadData = useSelector(catalogLinkPayloadSelector);
  const draftLinkPayloadData = useSelector(draftLinkPayloadSelector);
  const activeDraftSuggestion = useSelector(activeDraftSuggestionSelector);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasBannerImage = selectedFiles?.some(
    (item) => item?.filetype === 'image'
  );
  const attachedFileCount =
    selectedFiles?.filter((item) => item?.filetype === 'file').length ?? 0;
  // timeline state
  const { page } = useParams();
  let suggestionPage = false;
  let draftLink = false;
  let catalogLinkEmail = false;
  let shareCatalogPdf = false;
  let sendinviteEmail = false;
  let pageDetails;

  if (page == 'suggestions') {
    suggestionPage = true;
    pageDetails = {
      title: 'Share Suggestion',
      backButtonText: 'Back to Suggestion',
      backButtonUrl: `/suggestions/${suggestionEmailpayloadData?.suggestionId}`,
    };
  }
  if (page == 'catalogPdf') {
    shareCatalogPdf = true;
    pageDetails = {
      title: 'Send Catalog PDF',
      backButtonText: 'Back to Catalog',
      backButtonUrl:
        SessionStorageManager.getSessionStorage('last_catalog_url'),
    };
  }
  if (page == 'catalogLink') {
    catalogLinkEmail = true;
    pageDetails = {
      title: 'Send Catalog Link',
      backButtonText: 'Back to Catalog',
      backButtonUrl:
        SessionStorageManager.getSessionStorage('last_catalog_url'),
    };
  }
  if (page == 'draftLink') {
    draftLink = true;
    pageDetails = {
      title: 'Share Draft Order',
      backButtonText: 'Back to Drafts',
      backButtonUrl: `/drafts/${draftLinkPayloadData?.worksheetCollectionId}`,
    };
  }
  if (page == 'sendInviteEmail') {
    sendinviteEmail = true;
    pageDetails = {
      title: 'Send Invite Email',
      backButtonText: 'Back to My Cutomers',
      backButtonUrl:
        '/my-customer/retailers?srch=&page=1&filters=&filtersCount=',
    };
  }
  useEffect(() => {
    if (
      suggestionEmailpayloadData?.length == 0 &&
      catalogPdfPayloadData?.length == 0 &&
      catalogLinkPayloadData?.length == 0 &&
      draftLinkPayloadData?.length == 0 &&
      !sendinviteEmail
    ) {
      navigate('/');
    }
  }, [activeDraftSuggestion]);
  useEffect(() => {
    if (!Array.isArray(draftLinkPayloadData) && draftLink) {
      setSubject(draftLinkPayloadData?.subject ?? '');
      setMessage(draftLinkPayloadData?.message ?? '');
    }
    if (!Array.isArray(catalogLinkPayloadData) && catalogLinkEmail) {
      setSubject(catalogLinkPayloadData?.subject ?? '');
      setMessage(catalogLinkPayloadData?.message ?? '');
    }
    if (!Array.isArray(catalogPdfPayloadData) && shareCatalogPdf) {
      setSubject(catalogPdfPayloadData?.subject ?? '');
      setMessage(catalogPdfPayloadData?.message ?? '');
    }
    if (!Array.isArray(suggestionEmailpayloadData) && suggestionPage) {
      setSubject(suggestionEmailpayloadData?.subject ?? '');
      setMessage(suggestionEmailpayloadData?.message ?? '');
    }
  }, []);

  // check if there is data in payload
  // const removeImageOnfirstLoad = async () => {
  //   let formData = new FormData();

  //   if (draftLink) {
  //     formData.append('delete_imgfile', true);
  //     formData.append('worksheet_id', draftLinkPayloadData?.worksheetId);
  //     formData.append('worksheet_collection_id', worksheetCollectionId);
  //     formData.append('type', 'proposal');
  //   }
  //   if (suggestionPage) {
  //     formData.append('delete_imgfile', true);
  //     formData.append(
  //       'suggestion_id',
  //       suggestionEmailpayloadData?.suggestionId
  //     );
  //     formData.append('type', 'proposal');
  //   }

  //   let res = await uploadImage(formData, false, false);
  //   if (res) {
  //     setFileName('No File Chosen');
  //   }
  // };
  const getAllAttachedFiles = async () => {
    let payload = {};
    if (suggestionPage) {
      payload = {
        suggestion_id: suggestionEmailpayloadData?.suggestionId,
        type: 'suggestion',
      };
    }
    if (draftLink) {
      payload = {
        worksheet_id: draftLinkPayloadData?.worksheetId,
        worksheet_collection_id: draftLinkPayloadData?.worksheetCollectionId,
        type: 'proposal',
      };
    }
    const res = await api.post('/getAllAttachFiles', payload);
    if (res?.data?.status) {
      setSelectedFiles(res?.data?.data);
    }
  };
  useEffect(() => {
    // removeImageOnfirstLoad();
    if (suggestionPage || draftLink) getAllAttachedFiles();
  }, []);
  function advanceTimeline() {
    if (timeline < 4) setTimeline(timeline + 1);
  }

  function handleOpen() {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  // async function getBuyers({ searchTerm, salesRepId, alphaSort }) {
  //   const res = await api.get(
  //     `/draftBuyerList?worksheetId=123&search=${searchTerm}&salesRepId=${salesRepId}&alphasort=${alphaSort}`
  //   );
  // }
  async function uploadImage(formData, upload, showSnackbarbool = true) {
    const res = await api.post('/uploadAndDeleteFile', formData);
    if (res.status) {
      if (showSnackbarbool)
        dispatch(
          showSnackbar({
            snackbarMessage: upload
              ? 'File uploaded successfully'
              : 'File removed successfully',
            type: snackbarTypes.SUCCESS,
            snackbarAlign: {
              vertical: 'top',
              horizontal: 'right',
            },
          })
        );
    } else {
      dispatch(
        showSnackbar({
          snackbarMessage: upload
            ? 'There was an error uploading the image'
            : 'There was an error removing the image',
          type: snackbarTypes.ERROR,
          snackbarAlign: {
            vertical: 'top',
            horizontal: 'right',
          },
        })
      );
    }
    return res;
  }
  async function submit() {
    //temp array to store email request data
    var arr = [];
    if (isCopyMeSelected) {
      let profileType = '';
      if (user?.roleId == profileRoles?.buyer) {
        profileType = 'buyer';
      } else if (user?.roleId == profileRoles?.sales_rep) {
        profileType = 'salesRep';
      }
      arr.push({
        recipientId: user?.id,
        recipientName: user?.name,
        recipientEmail: user?.email,
        status: 'sending',
        type: profileType,
        message: message,
        subject: subject,
        sender: sender,
      });
    }
    if (selectedExternalContacts?.length > 0) {
      selectedExternalContacts?.forEach((contact) => {
        arr.push({
          recipientId: contact?.id,
          recipientName: contact?.contact_email,
          recipientEmail: contact?.contact_email,
          status: 'sending',
          type: 'externalContact',
          message: message,
          subject: subject,
          sender: sender,
        });
      });
    }
    // add buyers to emailRequests
    selectedBuyers?.forEach((buyer) => {
      arr.push({
        recipientId: buyer?.buyer_id,
        recipientName: buyer?.aname,
        recipientEmail: buyer?.email,
        status: 'sending',
        type: 'buyer',
        message: message,
        subject: subject,
        sender: sender,
      });
    });

    //add sales reps to emailRequests
    selectedAssociates?.forEach((salesRep) => {
      arr.push({
        recipientId: salesRep?.id,
        recipientName: salesRep?.name,
        recipientEmail: salesRep?.email,
        status: 'sending',
        type: 'salesRep',
        message: message,
        subject: subject,
        sender: sender,
      });
    });

    setEmailRequests(arr);
  }

  useEffect(() => {
    // sendEmail(emailRequests[index]).then((res) => {
    //   let emailRes = emailRequests[index];
    //   emailRes.status = res.data.status ? 'sent' : 'failed';
    //   setEmailResponses([...emailResponses, emailRes]);
    //   if (res.data.status) setEmailsSent(emailsSent + 1);
    //   if (index < emailRequests.length) {
    //     setIndex(index + 1);
    //   }
    // });
    if (emailRequests.length > 0) {
      setSendingEmails(true);
    }
  }, [emailRequests]);

  async function sendEmail() {
    var buyerDetails = {};
    var copyAssociateDetails = [];
    var buyerIds = [];
    if (isCopyMeSelected) {
      if (user?.roleId == profileRoles?.buyer) {
        buyerIds.push(user?.id.toString());
      } else if (user?.roleId == profileRoles?.sales_rep) {
        copyAssociateDetails.push(user?.id);
      }
    }

    // add buyers to emailRequests
    selectedBuyers?.forEach((buyer) => {
      buyerIds.push(buyer?.buyer_id.toString());
    });
    buyerDetails = {
      recipientId: buyerIds,
      recipientName: selectedBuyers[0]?.aname,
      recipientEmail: selectedBuyers[0]?.email,
      status: 'sending',
      type: 'buyer',
      message: message,
      subject: subject,
      sender: sender,
    };

    //add sales reps to emailRequests
    selectedAssociates?.forEach((salesRep) => {
      copyAssociateDetails.push(
        salesRep?.id
        // recipientName: salesRep?.name,
        // recipientEmail: salesRep?.email,
        // status: 'sending',
        // type: 'salesRep',
        // message: message,
        // subject: subject,
        // sender: sender,
      );
    });

    if (suggestionPage) {
      let res = api.post(`/shareSuggestionLink`, {
        copy_reps: copyAssociateDetails,
        buyer_id: buyerDetails.recipientId,
        group_ids: groups,
        message: message,
        subject: subject,
        signature: `${user.name}\n${user.company}\n\n${user.email}`,
        linesheet_id: suggestionEmailpayloadData?.suggestionId,
        catalog_link: suggestionEmailpayloadData?.catalogLink,
        pdf_link: suggestionEmailpayloadData?.pdfLink,
        sender: sender.value,
        isAllBuyerSelected: isAllBuyerSelected,
        selectedSalesRepId: salesRep?.value == 0 ? '' : salesRep?.value ?? '',
      });
      return res;
    }

    if (draftLink) {
      let res = api.post(`/sendSharedDraftLink`, {
        copy_reps: copyAssociateDetails,
        buyer_id: buyerDetails.recipientId,
        group_ids: groups,
        worksheet_id: draftLinkPayloadData?.worksheetId,
        worksheet_collection_id: draftLinkPayloadData?.worksheetCollectionId,
        show_inventory: showInventory,
        // message: message,
        isAllBuyerSelected: isAllBuyerSelected,
        selectedSalesRepId: salesRep?.value == 0 ? '' : salesRep?.value ?? '',
        postedData: {
          subject: subject,
          // message: message.replace(/<[^>]*>/g, ''),
          message: message,
        },
      });
      return res;
    }

    if (catalogLinkEmail) {
      let res = api.post(`/sendCatalogLinkEmail`, {
        copyReps: copyAssociateDetails,
        buyerId: buyerDetails.recipientId,
        groupIds: groups,
        copySelf: 'y',
        message: message,
        subject: subject,
        linkName: catalogLinkPayloadData?.shareLinkName ?? '',
        linkUrl: catalogLinkPayloadData?.catalogLink ?? '',
        isAllBuyerSelected: isAllBuyerSelected,
        selectedSalesRepId: salesRep?.value == 0 ? '' : salesRep?.value ?? '',
      });
      return res;
    }
    if (shareCatalogPdf) {
      let res = api.post(`/sendCatalogPdfEmail`, {
        subject: subject,
        message: message,
        catalogLink: window.location.href,
        catalogPdfLink: catalogPdfPayloadData?.catalogPdfLink,
        copySelf: 'y',
        copyReps: copyAssociateDetails,
        groupIds: groups,
        buyerId: buyerDetails.recipientId,
        isAllBuyerSelected: isAllBuyerSelected,
        selectedSalesRepId: salesRep?.value == 0 ? '' : salesRep?.value ?? '',
      });
      return res;
    }
    if (sendinviteEmail) {
      let res = await api.post(`/sendInviteEmail`, {
        buyer_id: buyerDetails.recipientId,
        group_ids: groups,
        sales_rep_id: selectedSignature.value,
        from_sender: selectedSender.value,
        isAllBuyerSelected: isAllBuyerSelected,
        doNotSendActiveBuyer: isSendActiveBuyerEmailChecked,
        selectedSalesRepId: salesRep?.value == 0 ? '' : salesRep?.value ?? '',
        postedData: {
          subject: subject,
          // message: message.replace(/<[^>]*>/g, ''),
          message: message,
        },
      });
      return res;
    }
  }

  // useEffect(() => {
  //   getBuyers({ draftId: 9339, searchTerm: '', salesRepId: 0, alphaSort: '' });
  // }, []);
  // add buyers to emailRequests

  //reset state when opening/ closing modal
  const getPreviewEmailContent = async () => {
    setGetPreviewLoader(true);
    const res = await api.post('getPreviewEmailContent', {
      type: sendinviteEmail
        ? 'buyerAccountCreated'
        : draftLink
        ? 'buyerNewWorksheetFromSalesRep'
        : suggestionPage
        ? 'suggestion'
        : '',
      BuyerId:
        selectedBuyers[0]?.buyer_id?.toString() ??
        selectedAssociates[0]?.id ??
        selectedExternalContacts[0]?.id ??
        '',
      subject: subject,
      // message: message.replace(/<[^>]*>/g, ''),
      message: message,
      worksheetCollectionId: draftLink
        ? draftLinkPayloadData?.worksheetCollectionId
        : '',
      worksheetId: draftLink ? draftLinkPayloadData?.worksheetId : '',
      salesRepId: sendinviteEmail ? selectedSignature.value : '',
      suggestionId: suggestionPage
        ? suggestionEmailpayloadData?.suggestionId
        : '',
      linesheet_id: suggestionEmailpayloadData?.suggestionId,
      catalog_link: suggestionEmailpayloadData?.catalogLink,
      pdf_link: suggestionEmailpayloadData?.pdfLink,
      sender: sender.value,
      signature: `${user.name}\n${user.company}\n\n${user.email}`,
      suggestionLinkFlow: suggestionPage ? 1 : 0,
    });
    if (res.data.status) {
      setOnFirstTimeLoad(false);
      setPreviewSubject(res?.data?.data?.subject);
      setPreviewMessage(res?.data?.data?.htmlcontent);
      setGetPreviewLoader(false);
      setShowPreview(true);
    } else {
      setGetPreviewLoader(false);
    }
  };
  // reset all data
  useEffect(() => {
    return () => {
      // Reset all the states to their initial values
      setSubject('');
      setMessage('');
      setSelectedAssociates([]);
      setSelectedBuyers([]);
      setPreviewSubject('');
      setPreviewMessage('');
      // ... reset other states
      dispatch(setCatalogLinkPayload([]));
      dispatch(setCatalogPdfPayload([]));
      dispatch(setSuggestionEmailPayload([]));
    };
  }, []);
  const setMessageAndSubject = useCallback(({ message, subject }) => {
    setMessage(message);
    setSubject(subject);
  }, []);
  return {
    selectedBuyers,
    setSelectedBuyers,
    selectedAssociates,
    setSelectedAssociates,
    subject,
    setSubject,
    editorValue,
    setEditorValue,
    message,
    setMessage,
    timeline,
    setTimeline,
    advanceTimeline,
    handleOpen,
    handleClose,
    submit,
    sendingEmails,
    setSendingEmails,
    emailRequests,
    client,
    sender,
    setSender,
    showInventory,
    setShowInventory,
    uploadImage,
    selectedSender,
    setSelectedSender,
    selectedSignature,
    setSelectedSignature,
    subjectEmptyError,
    setSubjectEmptyError,
    showPreview,
    setShowPreview,
    showSendingEmailAlertModal,
    setShowSendingEmailAlertModal,
    suggestionPage,
    draftLink,
    catalogLinkEmail,
    shareCatalogPdf,
    sendinviteEmail,
    isBuyersListExceeded,
    setIsBuyersListExceeded,
    isCopyAssociateListExceeded,
    setIsCopyAssociateListExceeded,
    sendEmail,
    pageDetails,
    isAllBuyerSelected,
    setIsAllBuyerSelected,
    groups,
    setGroups,
    salesRep,
    setSalesRep,
    messageEmptyError,
    setMessageEmptyError,
    isSendActiveBuyerEmailChecked,
    setIsSendActiveBuyerEmailChecked,
    isSelectAll,
    setIsSelectAll,
    emailValue,
    setEmailValue,
    totalBuyersSelected,
    setTotalBuyersSelected,
    isLoading,
    setIsLoading,
    onFirstTimeLoad,
    setOnFirstTimeLoad,
    getPreviewEmailContent,
    navigate,
    setMessageAndSubject,
    previewMessage,
    previewSubject,
    suggestionEmailpayloadData,
    draftLinkPayloadData,
    uploadFileLoader,
    setUploadFileLoader,
    selectedFiles,
    setSelectedFiles,
    uploadImageLoader,
    setUploadImageLoader,
    getPreviewLoader,
    hasBannerImage,
    attachedFileCount,
    isError,
    setIsError,
    fileLimit,
    isCopyMeSelected,
    setIsCopyMeSelected,
    selectedExternalContacts,
    setSelectedExternalContacts,
    showSuggestionWarningModal,
    setShowSuggestionWarningModal,
  };
}
