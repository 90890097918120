import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import { responsiveBreakpoints } from 'utils/utils';

const TopNotification = ({
  startIcon,
  message,
  hrefIcon,
  onClickCloseIcon,
}) => {
  const { mainWidth } = responsiveBreakpoints();

  return (
    <Stack
      sx={{
        minHeight: '21px',
        backgroundColor: '#0075FD',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 0px',
      }}
    >
      <Stack
        direction='row'
        sx={{
          width: mainWidth,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0px 50px',
        }}
      >
        <Stack
          spacing={1}
          direction='row'
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
            color: '#FFFFFF',
          }}
        >
          {startIcon ? (
            <IconButton sx={{ color: '#FFFFFF' }}>{startIcon}</IconButton>
          ) : null}

          <Typography
            variant='h4'
            sx={{
              fontWeight: 300,
              color: '#FFFFFF',
            }}
          >
            {message}
          </Typography>
          {hrefIcon ? hrefIcon : null}
        </Stack>
        <IconButton
          sx={{ color: '#FFFFFF', fontWeight: 300 }}
          onClick={onClickCloseIcon}
        >
          <CloseIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};
TopNotification.propTypes = {
  onClickCloseIcon: PropTypes.func,
  startIcon: PropTypes.node,
  message: PropTypes.string,
  hrefIcon: PropTypes.node,
};
TopNotification.defaultProps = {
  onClickCloseIcon: () => {},
  startIcon: null,
  message: '',
  hrefIcon: null,
};
export default TopNotification;
