import { React, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Menu from '@mui/material/Menu';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDownIcon from '../../assets/icons/arrow-down-icon';
import useSelect from './select-hooks';
import { Checkbox, IconButton, Radio, CircularProgress } from '@mui/material';
import GrowingSpinner from '../../components/loaders/growing-spinner.jsx';

export default function SelectOld({
  placeholder,
  label,
  variant,
  size,
  color,
  startAdornment,
  options,
  value,
  setValue,
  defaultValueId,
  defaultValue,
  setFormikValue,
  sx,
  error,
  helperText,
  id,
  required,
  marginBottom,
  multiselect,
  disabled,
  onChange,
  actions,
  allowDefaults,
  allowRemoveIcon,
  sxBox,
  showActionHighlighter,
  onClickCallback,
  showOptionLabel,
  isLoading,
}) {
  const { anchorEl, open, handleClick, handleClose } = useSelect();

  //on changing 'default' value, change selected values
  useEffect(() => {
    let arr = [];
    defaultValue?.options?.forEach((option) => {
      arr.push({
        label: option?.name,
        value: option?.id,
      });
    });
    //if (setValue) setValue(arr);

    if (arr.length > 0) setFormikValue(id, [...arr]);
  }, [defaultValue]);

  return (
    <Box marginBottom={marginBottom} sx={{ width: '100%', ...sxBox }}>
      {label ? <InputLabel required={required}>{label}</InputLabel> : null}
      <Button
        id={id}
        fullWidth
        variant={variant}
        size={size}
        color={color}
        disabled={disabled}
        onClick={(event) => {
          onClickCallback();
          handleClick(event);
        }}
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            fontWeight: 500,
          },
          size === 'medium' ? { height: '38px' } : null,
          error ? { borderColor: 'var(--error-color)' } : null,
          sx,
        ]}
      >
        <Stack
          direction='row'
          spacing={0.5}
          justifyContent='space-between'
          alignItems='center'
          sx={{ width: '100%', height: '100%' }}
        >
          {showActionHighlighter ? (
            <GrowingSpinner
              section='selectionActionPopover'
              sx={{
                top: '-9px',
                right: '3px',
              }}
            />
          ) : null}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity:
                (value?.label === '' || value == '' || value === null) &&
                color !== 'primary'
                  ? 0.4
                  : 1,
              color:
                color == 'primary'
                  ? 'var(--contained-primary-button-text-color)'
                  : '',
            }}
          >
            {startAdornment}
          </Box>
          <Box
            sx={{
              width: '100%',
              whiteSpace: 'nowrap',
              textAlign: 'left',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              opacity:
                (value?.label === '' || value == '' || value === null) &&
                color !== 'primary'
                  ? 0.4
                  : 1,
              color:
                color == 'primary'
                  ? 'var(--contained-primary-button-text-color)'
                  : '',
            }}
          >
            {(() => {
              if (multiselect) {
                if (value?.length === 0 || value === null) return placeholder;
                else {
                  let str = '';
                  for (let i = 0; i < value?.length; i++) {
                    if (i === value?.length - 1)
                      str = str.concat(value[i]?.label);
                    else str = str.concat(`${value[i]?.label}, `);
                  }
                  return str;
                }
              } else if (value?.label === '' || value == '' || value === null) {
                return placeholder;
              } else if (showOptionLabel) {
                return options.find((item) => item.value === value.label)
                  ?.label;
              } else {
                return value?.label;
              }
            })()}
          </Box>
          <Stack direction='row' spacing={1} alignItems='center'>
            {value?.label && allowRemoveIcon ? (
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setValue(null);
                  setFormikValue(id, null);
                }}
              >
                <CancelIcon
                  fontSize='small'
                  sx={{ color: '#ccc', '&:hover': { color: '#bbb' } }}
                />
              </IconButton>
            ) : null}
            {isLoading ? (
              <Box sx={{ height: '20px' }}>
                <CircularProgress size={18} />
              </Box>
            ) : null}
            {disabled ? null : (
              <IconButton
                sx={{
                  opacity:
                    (value?.label === '' || value == '' || value === null) &&
                    color !== 'primary'
                      ? 0.4
                      : 1,
                }}
              >
                <ArrowDownIcon
                  fill={
                    color == 'primary'
                      ? 'var(--contained-primary-button-text-color)'
                      : null
                  }
                  sx={{
                    marginLeft: '5px',
                    width: '15px',
                  }}
                />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </Button>
      {error ? (
        <Typography variant='body2' sx={{ color: 'var(--error-color)' }}>
          {helperText}
        </Typography>
      ) : null}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ maxHeight: '240px' }}
        PaperProps={{ sx: { minWidth: anchorEl && anchorEl.offsetWidth } }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {multiselect && allowDefaults && (
          <MenuItem sx={{ '&:hover': { background: '#fff' } }}>
            <Stack direction='row'>
              <span
                style={{ marginRight: '20px', opacity: 0.7, fontWeight: 300 }}
              >
                Select
              </span>
              <span style={{ opacity: 0.7, fontWeight: 300 }}>Default</span>
            </Stack>
          </MenuItem>
        )}
        {options?.map((option, i) => (
          <MenuItem
            disabled={option?.isDisabled}
            key={i}
            onClick={(e) => {
              e.preventDefault();
              if (
                (() => {
                  if (defaultValue) {
                    return defaultValue?.options?.some(
                      (defaultValueOption) =>
                        parseInt(defaultValueOption?.id) ===
                        parseInt(option?.value)
                    );
                  }
                  return true;
                })()
              ) {
                if (multiselect && Array.isArray(value)) {
                  if (value?.some((obj) => obj.value === option.value)) {
                    let arr = value?.filter(
                      (obj) => obj.value !== option.value
                    );
                    setValue(arr);
                    setFormikValue(id, arr);
                  } else {
                    setValue([...value, option]);
                    setFormikValue(id, [...value, option]);
                  }
                } else {
                  setValue(option);
                  setFormikValue(id, option);
                  onChange();
                  handleClose();
                }
              }
            }}
            // style={{ marginBottom: '-10px' }}
            sx={
              value?.value === option?.value
                ? {
                    backgroundColor: 'primary.main',
                    color: 'var(--contained-primary-button-text-color)',
                    '&:hover': {
                      color: 'var(--primary-font-color)',
                    },
                  }
                : null
            }
          >
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
            >
              {multiselect && (
                <Checkbox
                  sx={{ marginRight: '20px' }}
                  checked={(() => {
                    if (multiselect && Array.isArray(value)) {
                      return value?.some((obj) => obj?.value === option?.value);
                    }
                  })()}
                  disabled={(() => {
                    if (defaultValue) {
                      return !defaultValue?.options?.some(
                        (defaultValueOption) =>
                          parseInt(defaultValueOption?.id) ===
                          parseInt(option?.value)
                      );
                    }
                    return false;
                  })()}
                />
              )}

              {multiselect && allowDefaults && (
                <Radio
                  id={defaultValueId}
                  checked={defaultValue?.value === option.value}
                  sx={{
                    marginLeft: '29px',
                    marginRight: '30px',
                    width: '20px',
                    height: '20px',
                    '&:hover': { background: 'none' },
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setFormikValue(defaultValueId, option);
                  }}
                />
              )}
              <span>{option.label}</span>
            </Stack>
          </MenuItem>
        ))}

        {actions.map((item, i) => {
          return (
            <Box
              key={i}
              onClick={() => {
                handleClose();
              }}
            >
              {item}
            </Box>
          );
        })}
      </Menu>
    </Box>
  );
}

SelectOld.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  options: PropTypes.array,
  startAdornment: PropTypes.node,
  value: PropTypes.any,
  sx: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  setValue: PropTypes.func,
  setFormikValue: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
  marginBottom: PropTypes.string,
  multiselect: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  actions: PropTypes.array,
  allowDefaults: PropTypes.bool,
  defaultValueId: PropTypes.string,
  defaultValue: PropTypes.string,
  allowRemoveIcon: PropTypes.bool,
  sxBox: PropTypes.object,
  showActionHighlighter: PropTypes.bool,
  onClickCallback: PropTypes.func,
  showOptionLabel: PropTypes.bool,
  isLoading: PropTypes.bool,
};

SelectOld.defaultProps = {
  id: '',
  sx: {},
  placeholder: null,
  label: null,
  error: false,
  helperText: '',
  required: false,
  marginBottom: null,
  setValue: function () {},
  setFormikValue: function () {},
  multiselect: false,
  startAdornment: null,
  disabled: false,
  onChange: function () {},
  actions: [],
  size: 'medium',
  allowDefaults: false,
  options: [],
  defaultValueId: '',
  defaultValue: null,
  variant: 'outlined',
  color: 'secondary',
  allowRemoveIcon: true,
  value: '',
  sxBox: {},
  showActionHighlighter: false,
  onClickCallback: () => {},
  showOptionLabel: false,
  isLoading: false,
};
