import React, { useState, useEffect } from 'react';
import { LogLevel } from 'constants';
import LoggingService from 'utils/service/LoggingService';

export function useProductCardVariant({ product }) {
  // cartFlag state
  const [cartFlag, setCartFlag] = useState(
    product?.is_worksheet_item ? product?.is_worksheet_item : false
  );
  // suggestionFlag state
  const [suggestionFlag, setSuggestionFlag] = useState(
    product?.is_suggestion_item ? product?.is_suggestion_item : false
  );
  // itemCalculation state
  const [itemCalculation, setItemCalculation] = React.useState({
    itemQuantity: 0,
    itemTotal: 0.0,
  });
  // Quantity error flag State
  const [quantityErrorFlag, setQuantityErrorFlag] = React.useState({
    minQuantity: parseInt(product?.minimumPerStyle) !== 0 ? true : false,
    maxQuantity: parseInt(product?.maximumPerStyle) !== 0 ? true : false,
  });
  // cartActionLoader State
  const [cartActionLoader, setCartActionLoader] = useState(false);
  // suggestionActionLoader
  const [suggestionActionLoader, setSuggestionActionLoader] = useState(false);
  // state for productModalFlag
  const [productModalFlag, setProductModalFlag] = useState(false);
  // state of hovered suggestion
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setSuggestionFlag(
      product?.is_suggestion_item ? product?.is_suggestion_item : false
    );
  }, [product]);
  // =================> validate formik <============================
  const validateFormik = (values, color, nonStateQuantityErrorFlag) => {
    try {
      if (
        parseInt(product?.maximumPerStyle) !== 0 ||
        parseInt(product?.minimumPerStyle) !== 0
      ) {
        let x = 0;
        //Validation for sizes
        // if (colorData?.sizes && Object.keys(colorData?.sizes).length > 0) {
        //   validateSizes(values);
        // }
        product?.item_colors?.map((color, i) => {
          if (
            color?.prePackSizes &&
            Object.keys(color?.prePackSizes).length > 0
          ) {
            let totalQty = 0;
            let restValueId;
            color?.prePackSizes.forEach((prepackSize) => {
              if (Array.isArray(prepackSize)) {
                prepackSize.forEach((size, i) => {
                  if (i === 0) {
                    restValueId = `${size.prePackId}`;
                  }

                  totalQty +=
                    parseFloat(values[restValueId] ? values[restValueId] : 0) *
                    parseFloat(size.prepackQuantity ? size.prepackQuantity : 0);
                });
              }
            });

            x += totalQty || 0;
          } else {
            // As we are iterating on colors for Sizes but all the data is coming in single color that is why reinitializing it to 0
            x = 0;
            // sizes and dimensions validations
            if (values)
              Object.entries(values).map((value) => {
                x += parseInt(value[1] || 0);
              });
          }

          // Pre-pack sizes and sizes combination
          if (color?.prePackSizes && color?.sizes) {
            // As we are iterating on colors for Sizes but all the data is coming in single color that is why reinitializing it to 0
            x = 0;

            let totalQty = 0;
            let restValueId;
            color?.prePackSizes.forEach((prepackSize) => {
              if (Array.isArray(prepackSize)) {
                prepackSize.forEach((size, i) => {
                  if (i === 0) {
                    restValueId = `${size.prePackId}`;
                  }
                  totalQty +=
                    parseFloat(values[restValueId] ? values[restValueId] : 0) *
                    parseInt(size.prepackQuantity ? size.prepackQuantity : 0);
                });
              }
            });
            x += totalQty || 0;

            if (x > 0 || x == 0) {
              Object.entries(values).map((value) => {
                if (value[0].indexOf('SizePrepack') < 0) {
                  x += parseInt(value[1] || 0);
                }
              });
            }
          }
        });

        let updateProductQuantity = { ...quantityErrorFlag };
        // Do not check Minimum validation if it's not set
        if (parseInt(product?.minimumPerStyle) !== 0) {
          if (x > 0 && x >= parseInt(product?.minimumPerStyle)) {
            updateProductQuantity.minQuantity = false;
          } else {
            updateProductQuantity.minQuantity = true;
          }
        }
        // Do not check Maximum validation if it's not set
        if (parseInt(product?.maximumPerStyle) !== 0) {
          if (parseInt(product?.maximumPerStyle) < x || x == 0) {
            updateProductQuantity.maxQuantity = true;
          } else {
            updateProductQuantity.maxQuantity = false;
          }
        } else {
          updateProductQuantity.maxQuantity = false;
        }

        setQuantityErrorFlag({ ...updateProductQuantity });
        nonStateQuantityErrorFlag = { ...updateProductQuantity };
      }
    } catch (err) {
      LoggingService.log(
        'validateFormik',
        'useProductCardVariant',
        err,
        LogLevel.ERROR
      );
    }
  };
  const calculateItemTotalAndQuantity = (
    values,
    color,
    nonStateQuantityErrorFlag
  ) => {
    try {
      let totalQuantity = 0;
      let prepackQty = 0;
      let prepackValueEntered = 0;
      let costPerUnit = parseFloat(product?.per_unit_price);
      var totalCost = 0;
      product?.item_colors?.map((color, parentIndex) => {
        if (
          color?.prePackSizes &&
          Object.keys(color?.prePackSizes).length > 0
        ) {
          let totalQty = 0;
          let restValueId;
          let totalSizeCost = 0;
          color?.prePackSizes.forEach((prepackSize) => {
            if (Array.isArray(prepackSize)) {
              prepackSize.forEach((size, i) => {
                if (i === 0) {
                  restValueId = `${size.prePackId}`;
                }
                totalQty +=
                  parseFloat(values[restValueId] ? values[restValueId] : 0) *
                  parseFloat(size.prepackQuantity);

                totalSizeCost +=
                  parseFloat(
                    values[`${size.prePackId}`]
                      ? values[`${size.prePackId}`]
                      : 0
                  ) *
                  parseFloat(size.prepackQuantity) *
                  parseFloat(
                    size?.isSizeCost ? size?.sizeCost : costPerUnit
                  ).toFixed(2);
              });

              if (totalSizeCost > 0) {
                totalCost = totalCost + totalSizeCost;
              }
            }
          });

          totalQuantity += totalQty || 0;
        }

        if (color?.sizes && Object.keys(color?.sizes).length > 0) {
          let totalQty = 0;
          let totalSizeCost = 0;
          color?.sizes?.forEach((size) => {
            totalQty += parseFloat(
              values[size.sizeCombinationId]
                ? values[size.sizeCombinationId]
                : 0
            );

            totalSizeCost =
              parseFloat(
                values[size.sizeCombinationId]
                  ? values[size.sizeCombinationId]
                  : 0
              ).toFixed(2) *
              parseFloat(
                size?.isSizeCost ? size?.sizeCost : costPerUnit
              ).toFixed(2);
            if (totalSizeCost > 0) {
              totalCost = totalCost + totalSizeCost;
            }
          });
          totalQuantity += totalQty || 0;
        }

        if (color?.dimensions && Object.keys(color?.dimensions).length > 0) {
          let totalQty = 0;
          let totalDimensionCost = 0;
          color?.dimensions?.map((dimension, i) => {
            totalDimensionCost = 0;
            color?.dimensions?.forEach((dimension, j) => {
              dimension?.sizes?.forEach((size) => {
                if (i === j) {
                  totalQty += parseFloat(
                    values[size.sizeCombinationId]
                      ? values[size.sizeCombinationId]
                      : 0
                  );

                  totalDimensionCost +=
                    parseFloat(
                      values[size.sizeCombinationId]
                        ? values[size.sizeCombinationId]
                        : 0
                    ).toFixed(2) *
                    parseFloat(
                      size?.isSizeCost ? size?.sizeCost : costPerUnit
                    ).toFixed(2);
                }
              });
            });

            if (totalDimensionCost > 0) {
              totalCost = totalCost + totalDimensionCost;
            }
          });
          totalQuantity += totalQty || 0;
        }
      });

      totalQuantity += prepackQty || 0;
      totalQuantity -= prepackValueEntered || 0;
      //totalCost = totalCost ? totalCost.toFixed(2) : 0;
      // Check if price Tiers value is applied
      if (
        product?.price_tiers &&
        Object.keys(product?.price_tiers).length > 0
      ) {
        let tierMinQuantity = 0;
        let tierCost = 0;
        product?.price_tiers?.forEach((tier) => {
          tierMinQuantity = parseInt(tier.min_quantity);
          tierCost = parseInt(tier.price);
          //Change total Cost as per setting
          if (totalQuantity >= tierMinQuantity) {
            totalCost = totalQuantity * tierCost;
          }
        });
      }
      setItemCalculation({
        itemQuantity: totalQuantity,
        itemTotal: totalCost,
      });
    } catch (err) {
      LoggingService.log(
        'calculateItemTotalAndQuantity',
        'useProductCardVariant',
        err,
        LogLevel.ERROR
      );
    }
  };

  return {
    cartFlag,
    setCartFlag,
    suggestionFlag,
    setSuggestionFlag,
    calculateItemTotalAndQuantity,
    validateFormik,
    quantityErrorFlag,
    setQuantityErrorFlag,
    itemCalculation,
    setItemCalculation,
    cartActionLoader,
    setCartActionLoader,
    suggestionActionLoader,
    setSuggestionActionLoader,
    productModalFlag,
    setProductModalFlag,
    hovered,
    setHovered,
  };
}
