import {
  api,
  apiEndPoints,
  addActionLoader,
  removeActionLoader,
} from 'api/index';

const namespace = 'pages/suggestions/';

export const SET_API_ERROR = `${namespace}SET_API_ERROR`;
export const GET_RECEIVED_SUGGESTIONS = `${namespace}GET_RECEIVED_SUGGESTIONS`;
export const GET_SUGGESTION_LIST = `${namespace}GET_SUGGESTION_LIST`;

const setApiError = (error) => ({
  type: SET_API_ERROR,
  payload: error,
});

export const getSuggestions = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_SUGGESTION_LIST`));
    const response = await api.get(
      `${apiEndPoints.suggestionList}?page=${payload?.pageNumber ?? 1}`
    );
    const {
      data,
      data: { status = false },
    } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(`GET_SUGGESTION_LIST`));
  }
};

export const getReceivedSuggestions = (payload) => async (dispatch) => {
  try {
    dispatch(addActionLoader(`GET_RECEIVED_SUGGESTIONS`));
    const response = await api.get(
      `${apiEndPoints.getSharedSuggestions}?page=${payload?.page ?? 1}`
    );
    const {
      data,
      data: { status = false },
    } = response;
    if (status) {
      return data;
    }
    return false;
  } catch (error) {
    dispatch(setApiError(error));
    return false;
  } finally {
    dispatch(removeActionLoader(`GET_RECEIVED_SUGGESTIONS`));
  }
};
